#optimoveSdkWebTool {
    width: 0;
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 1000;
}

#optimoveSdkWebTool.open {
    max-width: 1500px;
    min-width: 340px;
    height: 100vh;
}

#optimoveSdkWebTool.open.ag-theme-balham {
    width: 500px;
    display: block;
    z-index: 10000;
    -webkit-box-shadow: -3px 0 10px 0 rgba(200, 200, 200, 0.5);
    box-shadow: -3px 0 10px 0 rgba(200, 200, 200, 0.5);
}

#optimovePanelHead.panel-heading {
    padding: 16px 15px;
}

#optimovePanelHead .popupDiv {
    padding-left: 334px;
    position: relative;
}

#optimovePanelHead .popupDiv .popup {
    position: absolute;
    visibility: hidden;
}

#optimovePanelHead .popupDiv .popup.showPopup {
    visibility: visible;
    display: block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    width: 109px;
    height: 84px;
    z-index: 1;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockRightPicDiv {
    height: 28px;
    padding-top: 10px;
    padding-left: 10px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockRightPicDiv .dockText {
    display: inline;
    padding-left: 6px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockRightPicDiv:hover {
    background-color: #ECEFF1;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockLeftPicDiv {
    height: 28px;
    padding-top: 10px;
    padding-left: 10px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockLeftPicDiv .dockText {
    display: inline;
    padding-left: 6px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockLeftPicDiv:hover {
    background-color: #ECEFF1;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockBottomPicDiv {
    height: 28px;
    padding-top: 10px;
    padding-left: 10px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockBottomPicDiv .dockText {
    display: inline;
    padding-left: 6px;
}

#optimovePanelHead .popupDiv .popup.showPopup .dockBottomPicDiv:hover {
    background-color: #ECEFF1;
}

#optimovePanelHead .popup.popupNotShow {
    visibility: hidden;
}

#optimovePanelHead .popup.showPopup {
    visibility: visible;
    display: block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    width: 109px;
    height: 83px;
}

#optimoveSdkWebTool.open.ag-theme-balham .ag-header-cell {
    padding-right: 0px !important;
}

#optimoveSDKDragger {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1;
    cursor: w-resize;
}

#optimoveSdkWebTool .material-icons {
    cursor: pointer;
}

#optimoveSdkWebTool .ag-root-wrapper.ag-layout-normal {
    height: calc(100% - 103px);
}

#optimoveSdkWebTool.ag-theme-balham .is-not-main {
    background-color: #ECEFF1;
}

#optimoveSdkWebTool.ag-theme-balham .errorNotMainRow {
    background-color: #FFD6C2;
}

#optimoveSdkWebTool.ag-theme-balham .ag-header-cell {
    background-color: #CFD8DC;
    box-sizing: content-box;
}

#optimoveSdkWebTool .ag-header-cell-text {
    color: #455A64;
    text-align: left;
    font: Bold 12px/14px Roboto;
    letter-spacing: 0;
    opacity: 1;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell {
    text-align: left;
    font: Regular 12px/14px Roboto;
    letter-spacing: 0;
    color: #37474F;
    opacity: 1;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell .fa-angle-right,
#optimoveSdkWebTool.ag-theme-balham .ag-cell .fa-angle-down {
    width: 18px;
    height: 18px;
    padding-right: 10px;
    color: #37474F;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell .fa-exclamation-circle {
    padding-right: 20px;
    width: 16px;
    height: 17px;
    color: #FF5500;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.mainRowClass.optDateCell.optHasError {
    color: #FF5500;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.mainRowClass.mainRowClassEventId.optHasError {
    color: #FF5500;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.mainRowClass.mainRowClassEventName.optHasError {
    color: #FF5500;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.notMainDateRowClass {
    left: 26px !important;
    white-space: normal !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.notMainDateRowClass.optParametersWarning {
    color: #212121 !important;
    font-weight: bold !important;
    left: 26px !important;
}

#optimoveSdkWebTool .cell-wrap-text {
    white-space: normal !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.notMainDateRowClass.optNotExistWarning {
    left: 26px !important;
    left: 26px !important;
    white-space: nowrap !important;
    color: #CF4500;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.notMainRowClass.notMainRowClassEventId {
    left: 160px !important;
    font-weight: bold;
    white-space: normal !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-cell.notMainRowClass.notMainRowClassEventId.optHasError {
    left: 160px !important;
    font-weight: normal;
    white-space: normal !important;
}

#optimoveSdkWebTool .ag-overlay-loading-center {
    text-align: center;
    font: Bold 24px/28px Roboto;
    letter-spacing: 0;
    color: #F2F0F0;
    opacity: 1;
}

#optimoveSdkWebTool .cell-parameter-value {
    width: 540px !important;
    ;
}

#optimoveSdkWebTool .ag-full-width-row {
    padding-left: 8px !important;
    padding-top: 16px !important;
}

#optimoveSdkWebTool .optimoveTopHeadLine {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#optimoveSdkWebTool .optimoveTopHeadLine .optimoveWebToolBtnClose {
    box-shadow: none;
}

#optimoveSdkWebTool .optimoveTopHeadLine .optimoveWebToolBtnClose i {
    font-size: 17px;
    color: #5F7C8A;
}

#optimoveSdkWebTool .optimoveTopHeadLine .positionButtonPic {
    padding-right: 10px;
}

#optimoveSdkWebTool .optimoveTopHeadLineText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    color: #424242;
    text-align: left;
    font: Light 18px/18px Roboto;
    letter-spacing: 0;
    opacity: 1;
    font-weight: 300;
    font-size: 18px;
}

#optimoveSdkWebTool .optimoveBottomHeadLineText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 5px;
    text-align: left;
    font: Regular 12px/16px Roboto;
    letter-spacing: 0;
    color: #546E7A;
    opacity: 1;
}

#optimoveSdkWebTool .opvArrowNoParams {
    visibility: hidden;
}

#optimoveSdkWebTool .btn-link.opt-btn-link {
    padding-top: 5px;
    padding-left: 0px;
    border: 0;
    cursor: pointer;
    color: #00AAFF;
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    text-shadow: none;
    background-color: white !important;
}

#optimoveSdkWebTool .btn-link.opt-btn-link:hover {
    color: #00699E !important;
    text-decoration: none;
}

#optimoveSdkWebTool .opt-btn-link.btn-link.disable-btn:hover {
    color: #B0BEC5 !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
    border-right: none;
}

#optimoveSdkWebTool .ag-row-hover {
    background-color: #F5F7F7 !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-row-selected.opti-row-class {
    background-color: white !important;
    border-color: #d9dcde;
}

#optimoveSdkWebTool.ag-theme-balham .ag-root {
    border: 0px !important;
}

#optimoveSdkWebTool.ag-theme-balham .ag-overlay-loading-center {
    border: 0px !important;
}


.kumulos-rating {
    display: flex;
    height: 20px;
    font-size: 16px;
    align-items: center;
    margin-top: 3px
}

.kumulos-rating .kumulos-rating-star {
    position: relative
}

.kumulos-rating .kumulos-rating-star .kumulos-rating-halfstar {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    overflow: hidden
}

.kumulos-rating .kumulos-rating-count {
    font-size: 12px;
    margin-left: 3px
}


@keyframes kumulos-anim-shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0)
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0)
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0)
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0)
    }
}

@keyframes kumulos-anim-fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes kumulos-reveal-left {
    0% {
        opacity: 0;
        transform: translate(10px, -50%)
    }

    100% {
        opacity: 1;
        transform: translate(0px, -50%)
    }
}

@keyframes kumulos-reveal-right {
    0% {
        opacity: 0;
        transform: translate(-10px, -50%)
    }

    100% {
        opacity: 1;
        transform: translate(0px, -50%)
    }
}

@keyframes kumulos-toast-in-out {
    0% {
        opacity: 0;
        transform: translate3d(-50%, 100%, 0)
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0)
    }
}

@keyframes kumulos-slide-down {
    0% {
        transform: translate3d(0, -100%, 0)
    }

    100% {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes kumulos-slide-up {
    0% {
        transform: translate3d(0, 100%, 0)
    }

    100% {
        transform: translate3d(0, 0, 0)
    }
}

.kumulos-tooltip-parent .kumulos-tooltip {
    display: none
}

.kumulos-tooltip-parent:hover .kumulos-tooltip {
    display: block
}

.kumulos-tooltip {
    background: #222;
    position: absolute;
    top: 50%;
    padding: 8px 12px;
    font: 14px helvetica, sans-serif;
    color: #eee;
    border-radius: 6px;
    white-space: nowrap;
    z-index: 1100;
    pointer-events: none;
    transform: translateY(-50%);
    will-change: opacity, transform
}

.kumulos-tooltip:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 100%;
    border: solid transparent;
    border-width: 8px;
    margin-top: -8px
}

.kumulos-tooltip-right {
    left: calc(100% + 12px);
    animation: kumulos-reveal-right .2s cubic-bezier(0.23, 1, 0.32, 1)
}

.kumulos-tooltip-right:after {
    border-right-color: #222;
    right: 100%
}

.kumulos-tooltip-left {
    right: calc(100% + 12px);
    animation: kumulos-reveal-left .2s cubic-bezier(0.23, 1, 0.32, 1)
}

.kumulos-tooltip-left:after {
    border-left-color: #222;
    left: 100%
}

.kumulos-toast {
    display: inline-block;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #222;
    z-index: 1100;
    padding: 12px;
    font-size: 16px;
    font-family: helvetica, sans-serif;
    color: #eee;
    border-radius: 8px 8px 0 0;
    animation: kumulos-toast-in-out .25s cubic-bezier(0.23, 1, 0.32, 1)
}

.kumulos-prompt {
    box-sizing: border-box;
    z-index: 1000
}

.kumulos-prompt *,
.kumulos-prompt *:before,
.kumulos-prompt *:after {
    box-sizing: inherit
}

.kumulos-action-button {
    border: none;
    margin-left: 10px;
    padding: 7px 10px;
    min-width: 100px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600
}

.kumulos-action-button:hover {
    cursor: pointer
}

.kumulos-action-button-cancel {
    background: none;
    color: #555
}

.kumulos-action-button-cancel:hover {
    background: #eee
}

.kumulos-action-button-confirm {
    border-radius: 3px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .54);
    background: #4c88e0;
    color: #fff
}

.kumulos-action-button-confirm:hover {
    opacity: .8
}

.kumulos-checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    line-height: 20px;
    font-size: .9em;
    font-weight: bold
}

.kumulos-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.kumulos-checkbox {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid #ddd
}

.kumulos-checkbox-container:hover input~.kumulos-checkbox {
    border-color: #aaa;
    display: block
}

.kumulos-checkbox-container:hover input~.kumulos-checkbox:after {
    display: block;
    border-color: #aaa;
    box-shadow: none
}

.kumulos-checkbox:after {
    content: "";
    position: absolute;
    display: none
}

.kumulos-checkbox-container input:checked~.kumulos-checkbox {
    border-color: #aaa
}

.kumulos-checkbox-container input:checked~.kumulos-checkbox:after {
    display: block;
    border-color: #222;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .3)
}

.kumulos-checkbox-container .kumulos-checkbox:after {
    left: 10px;
    top: -7px;
    width: 8px;
    height: 20px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg)
}

.kumulos-banner-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    position: fixed;
    left: 0;
    width: 100%;
    min-height: 80px;
    padding: 10px;
    font-family: helvetica, sans-serif;
    backface-visibility: hidden;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .54)
}

.kumulos-banner-container.kumulos-prompt-position-top {
    top: 0;
    animation: kumulos-slide-down .4s ease
}

.kumulos-banner-container.kumulos-prompt-position-bottom {
    bottom: 0;
    top: unset;
    animation: kumulos-slide-up .4s ease
}

@media only screen and (min-device-width: 480px) {
    .kumulos-banner-container {
        flex-flow: initial
    }
}

.kumulos-banner-icon {
    width: 65px;
    height: 65px;
    margin-right: 10px
}

.kumulos-banner-content {
    display: flex;
    flex-direction: column;
    flex: 1
}

.kumulos-banner-header {
    display: flex;
    font-size: 12px;
    line-height: 14px
}

.kumulos-banner-header h1 {
    font-size: inherit;
    font-weight: bold;
    margin: 0 0 3px 0
}

@media only screen and (min-device-width: 480px) {
    .kumulos-banner-header {
        font-size: 18px
    }
}

.kumulos-banner-body {
    display: flex;
    flex-grow: 2;
    align-items: flex-start;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    margin-top: 10px
}

@media only screen and (min-device-width: 480px) {
    .kumulos-banner-body {
        font-size: 14px
    }
}

.kumulos-banner-actions {
    display: flex;
    align-self: center;
    justify-self: flex-end;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px
}

@media only screen and (min-device-width: 480px) {
    .kumulos-banner-actions {
        width: auto;
        margin-top: 0
    }
}

.kumulos-banner-compact .kumulos-banner-body {
    margin-top: 0
}

.kumulos-banner-compact .kumulos-banner-actions {
    width: auto;
    margin-top: 0
}

.kumulos-banner-compact .kumulos-banner-close {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px
}

@supports(padding: max(0px)) {
    .kumulos-safe-area-inset-pad-left {
        padding-left: max(10px, env(safe-area-inset-left))
    }

    .kumulos-safe-area-inset-pad-right {
        padding-right: max(10px, env(safe-area-inset-right))
    }

    .kumulos-safe-area-inset-pad-top {
        padding-top: max(10px, env(safe-area-inset-top))
    }

    .kumulos-safe-area-inset-pad-bottom {
        padding-bottom: max(10px, env(safe-area-inset-bottom))
    }
}

.kumulos-bell-container {
    position: fixed
}

.kumulos-bell-container-bottom-left {
    bottom: 15px;
    left: 15px
}

.kumulos-bell-container-bottom-right {
    bottom: 15px;
    right: 15px
}

.kumulos-bell {
    transition: transform .2s cubic-bezier(0.23, 1, 0.32, 1), box-shadow .2s cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 100%;
    border-color: #fff;
    background: #4c88e0;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .54);
    width: 60px;
    height: 60px;
    padding: 10px;
    transform: scale(0.9);
    cursor: pointer;
    animation: kumulos-anim-fade-in .2s ease-in;
    backface-visibility: hidden
}

.kumulos-bell:hover {
    transform: scale(1);
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, .54)
}

.kumulos-bell:active {
    transform: scale(0.8);
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .54)
}

.kumulos-bell svg {
    fill: #fff;
    animation: kumulos-anim-shake .4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: .3s
}

.kumulos-bell:before {
    content: "";
    display: block;
    width: 85%;
    height: 85%;
    border: solid 1px;
    border-radius: 100%;
    border-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    margin: 7.5% 0 0 7.5%
}

.kumulos-bell-inner {
    border-radius: 100%
}

.kumulos-channel-dialog-container,
.kumulos-alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: helvetica, sans-serif;
    animation: kumulos-anim-fade-in .2s ease-in;
    backface-visibility: hidden
}

@media only screen and (min-device-width: 480px) {

    .kumulos-channel-dialog-container,
    .kumulos-alert-container {
        left: 50%;
        width: 500px;
        min-height: 250px;
        margin-left: -250px;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, .54);
        height: auto
    }

    .kumulos-channel-dialog-container.kumulos-prompt-position-top-center,
    .kumulos-alert-container.kumulos-prompt-position-top-center {
        border-radius: 0 0 3px 3px
    }

    .kumulos-channel-dialog-container.kumulos-prompt-position-center,
    .kumulos-alert-container.kumulos-prompt-position-center {
        border-radius: 3px;
        top: 120px
    }
}

.kumulos-channel-dialog-icon,
.kumulos-alert-icon {
    order: 2;
    width: 60px;
    height: 60px;
    align-self: flex-start
}

.kumulos-channel-dialog-content,
.kumulos-alert-content {
    display: flex;
    order: 1;
    flex-direction: column;
    flex: 1;
    margin-right: 10px
}

.kumulos-channel-dialog-header,
.kumulos-alert-header {
    display: flex;
    font-size: 18px;
    margin-bottom: 20px
}

.kumulos-channel-dialog-header h1,
.kumulos-alert-header h1 {
    font-size: inherit;
    font-weight: 400;
    margin: 0
}

.kumulos-channel-dialog-body,
.kumulos-alert-body {
    flex: 1;
    white-space: pre-wrap;
    overflow-y: auto
}

.kumulos-channel-dialog-actions,
.kumulos-alert-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    order: 3;
    width: 100%;
    height: 30px;
    align-self: flex-end
}

.kumulos-channel-list-container {
    margin: 20px 0
}

body.kumulos-background-mask-blur {
    overflow: hidden
}

.kumulos-background-mask-blur>*:not(.kumulos-prompt):not(.kumulos-overlay):not(.kumulos-background-mask) {
    filter: blur(3px)
}

.kumulos-background-mask {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 900;
    background-color: rgba(0, 0, 0, .3);
    display: block
}

.kumulos-overlay-blur>*:not(.kumulos-overlay) {
    filter: blur(3px)
}

.kumulos-overlay {
    box-sizing: border-box;
    z-index: 2000
}

.kumulos-overlay *,
.kumulos-overlay *:before,
.kumulos-overlay *:after {
    box-sizing: inherit
}

.kumulos-overlay-strip {
    display: flex;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .8);
    min-height: 150px
}

.kumulos-overlay-strip:before {
    display: block;
    content: "";
    width: calc(500px + 12%);
    max-width: 660px
}

.kumulos-overlay-content-container {
    display: flex;
    max-width: 38%;
    padding: 1em;
    font-size: 14px;
    font-family: helvetica, sans-serif
}

.kumulos-overlay-content-container * {
    margin: 0
}

.kumulos-overlay-content-container svg {
    margin: 55px 1.7em 0 0;
    stroke: none;
    fill: rgba(0, 0, 0, .8);
    flex-shrink: 0
}

.kumulos-overlay-content-container img {
    width: 55px;
    height: 55px;
    flex-shrink: 0
}

.kumulos-overlay-content-container h3,
.kumulos-overlay-content-container p,
.kumulos-overlay-content-container a {
    color: inherit
}

.kumulos-overlay-content-container h3 {
    font-size: 1.3em;
    font-weight: bold
}

.kumulos-overlay-content-container a {
    margin-right: .65em;
    opacity: .8;
    text-decoration: none
}

.kumulos-overlay .kumulos-overlay-content {
    margin-left: .75em
}

.kumulos-overlay .kumulos-overlay-content p {
    margin: .5em 0
}

.kumulos-overlay .kumulos-silent-overlay-indicator {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1
}

.kumulos-overlay .kumulos-silent-overlay-content-text {
    max-width: 500px;
    text-align: center;
    font-size: 1.3em;
    font-family: helvetica, sans-serif
}

.kumulos-overlay-chrome .kumulos-overlay-strip:before {
    width: 440px
}

.kumulos-overlay-edge .kumulos-overlay-strip:before {
    display: none
}

.kumulos-overlay-edge .kumulos-overlay-strip {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 60px;
    justify-content: center
}

.kumulos-overlay-edge .kumulos-overlay-content-container {
    min-width: 675px
}

.kumulos-overlay-edge .kumulos-overlay-content-container svg {
    order: 1;
    transform: rotate(-30deg)
}

.kumulos-overlay-safari .kumulos-overlay-strip:before {
    width: calc(50% + 212px);
    max-width: 100%
}

@media(max-width: 900px) {
    .kumulos-overlay {
        display: none
    }
}


@keyframes intercom-lightweight-app-launcher {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes intercom-lightweight-app-gradient {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes intercom-lightweight-app-messenger {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    40% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.intercom-lightweight-app {
    position: fixed;
    z-index: 2147483001;
    width: 0;
    height: 0;
    font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
}

.intercom-lightweight-app-gradient {
    position: fixed;
    z-index: 2147483002;
    width: 500px;
    height: 500px;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background: radial-gradient(ellipse at bottom right,
            rgba(29, 39, 54, 0.16) 0%,
            rgba(29, 39, 54, 0) 72%);
    animation: intercom-lightweight-app-gradient 200ms ease-out;
}

.intercom-lightweight-app-launcher {
    position: fixed;
    z-index: 2147483003;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    bottom: 40px;
    right: 40px;
    max-width: 48px;
    width: 48px;
    max-height: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ffbb00;
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
    transition: transform 167ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
    box-sizing: content-box;
}


.intercom-lightweight-app-launcher:hover {
    transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
    transform: scale(1.1)
}

.intercom-lightweight-app-launcher:active {
    transform: scale(0.85);
    transition: transform 134ms cubic-bezier(0.45, 0, 0.2, 1);
}


.intercom-lightweight-app-launcher:focus {
    outline: none;


}

.intercom-lightweight-app-launcher-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    transition: transform 100ms linear, opacity 80ms linear;
}

.intercom-lightweight-app-launcher-icon-open {

    opacity: 1;
    transform: rotate(0deg) scale(1);

}

.intercom-lightweight-app-launcher-icon-open svg {
    width: 24px;
    height: 24px;
}

.intercom-lightweight-app-launcher-icon-open svg path {
    fill: rgb(255, 255, 255);
}

.intercom-lightweight-app-launcher-icon-self-serve {

    opacity: 1;
    transform: rotate(0deg) scale(1);

}

.intercom-lightweight-app-launcher-icon-self-serve svg {
    height: 44px;
}

.intercom-lightweight-app-launcher-icon-self-serve svg path {
    fill: rgb(255, 255, 255);
}

.intercom-lightweight-app-launcher-custom-icon-open {
    max-height: 24px;
    max-width: 24px;


    opacity: 1;
    transform: rotate(0deg) scale(1);

}

.intercom-lightweight-app-launcher-icon-minimize {

    opacity: 0;
    transform: rotate(-60deg) scale(0);

}

.intercom-lightweight-app-launcher-icon-minimize svg path {
    fill: rgb(255, 255, 255);
}

.intercom-lightweight-app-messenger {
    position: fixed;
    z-index: 2147483003;
    overflow: hidden;
    background-color: white;
    animation: intercom-lightweight-app-messenger 250ms cubic-bezier(0, 1, 1, 1);
    transform-origin: bottom right;


    width: 400px;
    height: calc(100% - 124px);
    max-height: 704px;
    min-height: 250px;
    right: 40px;
    bottom: 104px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);


    border-radius: 16px;
}

.intercom-lightweight-app-messenger-header {
    height: 64px;
    border-bottom: none;
    background: #FFBB00
}

.intercom-lightweight-app-messenger-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    font-size: 14px;
    line-height: 21px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

}

@media print {
    .intercom-lightweight-app {
        display: none;
    }
}