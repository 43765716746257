button.svelte-1tio7sv {
    border: none;
    font-size: 16px;
    border-radius: 8px;
    transition: all .5s;
    cursor: pointer;
    font-weight: 600;
    display: inline-flex;
    height: 48px;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: #fdcf39;
    color: #1b1b1f;
}