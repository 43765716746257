.st0 {
  fill: url(#SVGID_1_);
}

.st1 {
  fill: url(#SVGID_2_);
}

.st2 {
  fill: url(#SVGID_3_);
}