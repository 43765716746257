html,
body,
#app {
    height: 100%;
    margin: 0
}

main.svelte-jpae8n {
    background-color: #2d2d2f;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fdcf39;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center
}