.notification.svelte-1gz4987.svelte-1gz4987 {
    display: flex;
    flex-direction: column;
    max-width: 336px;
    background-color: #1b1b1f;
    border-radius: 16px;
    padding: 1rem;
    margin-bottom: 8px;
    transition: all .3s ease-in-out
}

.notification.svelte-1gz4987 .notification-header.svelte-1gz4987 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 13px
}

.notification.svelte-1gz4987 .notification-header .title.svelte-1gz4987 {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin: 0
}

.notification.svelte-1gz4987 .notification-wrapper img.svelte-1gz4987 {
    width: 100%;
    height: 168px;
    object-fit: cover;
    border-radius: 16px
}

.notification.svelte-1gz4987 .notification-wrapper .bottom-tab.svelte-1gz4987 {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 12px 0 0;
    flex-direction: column;
    gap: 8px
}

.notification.svelte-1gz4987 .notification-wrapper .bottom-tab .description.svelte-1gz4987 {
    margin: 0 20px 0 0;
    font-size: 14px;
    line-height: 22px
}

@media (max-width: 992px) {
    .notification.svelte-1gz4987.svelte-1gz4987 {
        max-width: 100%;
        width: 100%;
        right: 0;
        bottom: 0
    }

    .notification.svelte-1gz4987 .notification-wrapper .bottom-tab.svelte-1gz4987 {
        flex-direction: column;
        align-items: flex-start
    }
}

section.svelte-x1r39b {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9999999999;
    max-width: 336px;
    padding: 0
}