.win-notification.svelte-1x9akzz.svelte-1x9akzz {
    border-radius: 24px;
    border: 1px solid #42424d;
    background: #151518;
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
}

@media only screen and (max-width: 768px) {
    .win-notification.svelte-1x9akzz.svelte-1x9akzz {
        width: 100%
    }
}

.win-notification.svelte-1x9akzz .amount.svelte-1x9akzz {
    color: #fdcf39;
    text-align: center
}

.win-notification.svelte-1x9akzz .close.svelte-1x9akzz {
    background: #1c1c21;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer
}

.win-notification.svelte-1x9akzz .close.svelte-1x9akzz:hover {
    background: #26262c
}

.win-notification.svelte-1x9akzz .close:hover img.svelte-1x9akzz {
    transition: .9s;
    transform: rotate(-90deg)
}

.country-restricted.svelte-xhaqy {
    position: relative;
    width: calc(100% - 32px);
    max-width: 544px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 42px;
    border-radius: 16px;
    border: 1px solid var(--Secondary-200, #2f2f37);
    background: var(--Secondary-500, #151518)
}

@media only screen and (max-width: 768px) {
    .country-restricted.svelte-xhaqy {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
}

.title.svelte-xhaqy {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%
}

.description.svelte-xhaqy {
    color: #fffc;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%
}

div.svelte-xhaqy {
    text-align: center
}

.close-button.svelte-xhaqy {
    background: #1c1c21;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 16px;
    transition: all .3s ease-in-out
}

@media only screen and (max-width: 768px) {
    .close-button.svelte-xhaqy {
        right: 9px;
        top: 9px
    }
}

.close-button.svelte-xhaqy:hover {
    transform: rotate(90deg);
    background: #2f2f37
}