@import"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";

*,
*:before,
*:after {
    box-sizing: border-box
}

* {
    margin: 0;
    padding: 0
}

html,
body,
#app {
    height: 100%
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%
}

input,
button,
textarea,
select {
    font: inherit
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word
}

button {
    border: none;
    cursor: pointer
}

li {
    list-style: none
}

::-webkit-scrollbar {
    width: 4px;
    height: 2px
}

::-webkit-scrollbar-thumb {
    background: #474747;
    border-radius: 16px
}

::-webkit-scrollbar-track {
    background: transparent
}

::-webkit-scrollbar-thumb:vertical {
    min-height: 50px
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

.gap-2 {
    gap: 2px
}

.gap-4 {
    gap: 4px
}

.gap-8 {
    gap: 8px
}

.gap-10 {
    gap: 10px
}

.gap-12 {
    gap: 12px
}

.gap-14 {
    gap: 14px
}

.gap-16 {
    gap: 16px
}

.m-2 {
    margin: .125rem
}

.mx-2 {
    margin-left: .125rem;
    margin-right: .125rem
}

.my-2 {
    margin-top: .125rem;
    margin-bottom: .125rem
}

.ms-2 {
    margin-left: .125rem
}

.me-2 {
    margin-right: .125rem
}

.mb-2 {
    margin-bottom: .125rem
}

.mt-2 {
    margin-top: .125rem
}

.p-2 {
    padding: .125rem
}

.px-2 {
    padding-left: .125rem;
    padding-right: .125rem
}

.py-2 {
    padding-top: .125rem;
    padding-bottom: .125rem
}

.ps-2 {
    padding-left: .125rem
}

.pe-2 {
    padding-right: .125rem
}

.pb-2 {
    padding-bottom: .125rem
}

.pt-2 {
    padding-top: .125rem
}

.m-4 {
    margin: .25rem
}

.mx-4 {
    margin-left: .25rem;
    margin-right: .25rem
}

.my-4 {
    margin-top: .25rem;
    margin-bottom: .25rem
}

.ms-4 {
    margin-left: .25rem
}

.me-4 {
    margin-right: .25rem
}

.mb-4 {
    margin-bottom: .25rem
}

.mt-4 {
    margin-top: .25rem
}

.p-4 {
    padding: .25rem
}

.px-4 {
    padding-left: .25rem;
    padding-right: .25rem
}

.py-4 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.ps-4 {
    padding-left: .25rem
}

.pe-4 {
    padding-right: .25rem
}

.pb-4 {
    padding-bottom: .25rem
}

.pt-4 {
    padding-top: .25rem
}

.m-8 {
    margin: .5rem
}

.mx-8 {
    margin-left: .5rem;
    margin-right: .5rem
}

.my-8 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.ms-8 {
    margin-left: .5rem
}

.me-8 {
    margin-right: .5rem
}

.mb-8 {
    margin-bottom: .5rem
}

.mt-8 {
    margin-top: .5rem
}

.p-8 {
    padding: .5rem
}

.px-8 {
    padding-left: .5rem;
    padding-right: .5rem
}

.py-8 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.ps-8 {
    padding-left: .5rem
}

.pe-8 {
    padding-right: .5rem
}

.pb-8 {
    padding-bottom: .5rem
}

.pt-8 {
    padding-top: .5rem
}

.m-10 {
    margin: .625rem
}

.mx-10 {
    margin-left: .625rem;
    margin-right: .625rem
}

.my-10 {
    margin-top: .625rem;
    margin-bottom: .625rem
}

.ms-10 {
    margin-left: .625rem
}

.me-10 {
    margin-right: .625rem
}

.mb-10 {
    margin-bottom: .625rem
}

.mt-10 {
    margin-top: .625rem
}

.p-10 {
    padding: .625rem
}

.px-10 {
    padding-left: .625rem;
    padding-right: .625rem
}

.py-10 {
    padding-top: .625rem;
    padding-bottom: .625rem
}

.ps-10 {
    padding-left: .625rem
}

.pe-10 {
    padding-right: .625rem
}

.pb-10 {
    padding-bottom: .625rem
}

.pt-10 {
    padding-top: .625rem
}

.m-12 {
    margin: .75rem
}

.mx-12 {
    margin-left: .75rem;
    margin-right: .75rem
}

.my-12 {
    margin-top: .75rem;
    margin-bottom: .75rem
}

.ms-12 {
    margin-left: .75rem
}

.me-12 {
    margin-right: .75rem
}

.mb-12 {
    margin-bottom: .75rem
}

.mt-12 {
    margin-top: .75rem
}

.p-12 {
    padding: .75rem
}

.px-12 {
    padding-left: .75rem;
    padding-right: .75rem
}

.py-12 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.ps-12 {
    padding-left: .75rem
}

.pe-12 {
    padding-right: .75rem
}

.pb-12 {
    padding-bottom: .75rem
}

.pt-12 {
    padding-top: .75rem
}

.m-14 {
    margin: .875rem
}

.mx-14 {
    margin-left: .875rem;
    margin-right: .875rem
}

.my-14 {
    margin-top: .875rem;
    margin-bottom: .875rem
}

.ms-14 {
    margin-left: .875rem
}

.me-14 {
    margin-right: .875rem
}

.mb-14 {
    margin-bottom: .875rem
}

.mt-14 {
    margin-top: .875rem
}

.p-14 {
    padding: .875rem
}

.px-14 {
    padding-left: .875rem;
    padding-right: .875rem
}

.py-14 {
    padding-top: .875rem;
    padding-bottom: .875rem
}

.ps-14 {
    padding-left: .875rem
}

.pe-14 {
    padding-right: .875rem
}

.pb-14 {
    padding-bottom: .875rem
}

.pt-14 {
    padding-top: .875rem
}

.m-16 {
    margin: 1rem
}

.mx-16 {
    margin-left: 1rem;
    margin-right: 1rem
}

.my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.ms-16 {
    margin-left: 1rem
}

.me-16 {
    margin-right: 1rem
}

.mb-16 {
    margin-bottom: 1rem
}

.mt-16 {
    margin-top: 1rem
}

.p-16 {
    padding: 1rem
}

.px-16 {
    padding-left: 1rem;
    padding-right: 1rem
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.ps-16 {
    padding-left: 1rem
}

.pe-16 {
    padding-right: 1rem
}

.pb-16 {
    padding-bottom: 1rem
}

.pt-16 {
    padding-top: 1rem
}

.m-18 {
    margin: 1.125rem
}

.mx-18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem
}

.my-18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem
}

.ms-18 {
    margin-left: 1.125rem
}

.me-18 {
    margin-right: 1.125rem
}

.mb-18 {
    margin-bottom: 1.125rem
}

.mt-18 {
    margin-top: 1.125rem
}

.p-18 {
    padding: 1.125rem
}

.px-18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem
}

.py-18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem
}

.ps-18 {
    padding-left: 1.125rem
}

.pe-18 {
    padding-right: 1.125rem
}

.pb-18 {
    padding-bottom: 1.125rem
}

.pt-18 {
    padding-top: 1.125rem
}

.m-20 {
    margin: 1.25rem
}

.mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
}

.my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}

.ms-20 {
    margin-left: 1.25rem
}

.me-20 {
    margin-right: 1.25rem
}

.mb-20 {
    margin-bottom: 1.25rem
}

.mt-20 {
    margin-top: 1.25rem
}

.p-20 {
    padding: 1.25rem
}

.px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.ps-20 {
    padding-left: 1.25rem
}

.pe-20 {
    padding-right: 1.25rem
}

.pb-20 {
    padding-bottom: 1.25rem
}

.pt-20 {
    padding-top: 1.25rem
}

.m-22 {
    margin: 1.375rem
}

.mx-22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem
}

.my-22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem
}

.ms-22 {
    margin-left: 1.375rem
}

.me-22 {
    margin-right: 1.375rem
}

.mb-22 {
    margin-bottom: 1.375rem
}

.mt-22 {
    margin-top: 1.375rem
}

.p-22 {
    padding: 1.375rem
}

.px-22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem
}

.py-22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem
}

.ps-22 {
    padding-left: 1.375rem
}

.pe-22 {
    padding-right: 1.375rem
}

.pb-22 {
    padding-bottom: 1.375rem
}

.pt-22 {
    padding-top: 1.375rem
}

.m-24 {
    margin: 1.5rem
}

.mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

.my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.ms-24 {
    margin-left: 1.5rem
}

.me-24 {
    margin-right: 1.5rem
}

.mb-24 {
    margin-bottom: 1.5rem
}

.mt-24 {
    margin-top: 1.5rem
}

.p-24 {
    padding: 1.5rem
}

.px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.ps-24 {
    padding-left: 1.5rem
}

.pe-24 {
    padding-right: 1.5rem
}

.pb-24 {
    padding-bottom: 1.5rem
}

.pt-24 {
    padding-top: 1.5rem
}

.m-26 {
    margin: 1.625rem
}

.mx-26 {
    margin-left: 1.625rem;
    margin-right: 1.625rem
}

.my-26 {
    margin-top: 1.625rem;
    margin-bottom: 1.625rem
}

.ms-26 {
    margin-left: 1.625rem
}

.me-26 {
    margin-right: 1.625rem
}

.mb-26 {
    margin-bottom: 1.625rem
}

.mt-26 {
    margin-top: 1.625rem
}

.p-26 {
    padding: 1.625rem
}

.px-26 {
    padding-left: 1.625rem;
    padding-right: 1.625rem
}

.py-26 {
    padding-top: 1.625rem;
    padding-bottom: 1.625rem
}

.ps-26 {
    padding-left: 1.625rem
}

.pe-26 {
    padding-right: 1.625rem
}

.pb-26 {
    padding-bottom: 1.625rem
}

.pt-26 {
    padding-top: 1.625rem
}

.m-28 {
    margin: 1.75rem
}

.mx-28 {
    margin-left: 1.75rem;
    margin-right: 1.75rem
}

.my-28 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
}

.ms-28 {
    margin-left: 1.75rem
}

.me-28 {
    margin-right: 1.75rem
}

.mb-28 {
    margin-bottom: 1.75rem
}

.mt-28 {
    margin-top: 1.75rem
}

.p-28 {
    padding: 1.75rem
}

.px-28 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}

.py-28 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
}

.ps-28 {
    padding-left: 1.75rem
}

.pe-28 {
    padding-right: 1.75rem
}

.pb-28 {
    padding-bottom: 1.75rem
}

.pt-28 {
    padding-top: 1.75rem
}

.m-32 {
    margin: 2rem
}

.mx-32 {
    margin-left: 2rem;
    margin-right: 2rem
}

.my-32 {
    margin-top: 2rem;
    margin-bottom: 2rem
}

.ms-32 {
    margin-left: 2rem
}

.me-32 {
    margin-right: 2rem
}

.mb-32 {
    margin-bottom: 2rem
}

.mt-32 {
    margin-top: 2rem
}

.p-32 {
    padding: 2rem
}

.px-32 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-32 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.ps-32 {
    padding-left: 2rem
}

.pe-32 {
    padding-right: 2rem
}

.pb-32 {
    padding-bottom: 2rem
}

.pt-32 {
    padding-top: 2rem
}

.m-auto {
    margin: auto
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto
}

.ms-auto {
    margin-left: auto
}

.me-auto {
    margin-right: auto
}

.mb-auto {
    margin-bottom: auto
}

.mt-auto {
    margin-top: auto
}

.justify-center {
    justify-content: center
}

.items-center {
    align-items: center
}

.justify-items-center {
    justify-items: center
}

.content-center {
    align-content: center
}

.flex-center {
    flex-direction: center
}

.justify-space-between {
    justify-content: space-between
}

.items-space-between {
    align-items: space-between
}

.justify-items-space-between {
    justify-items: space-between
}

.content-space-between {
    align-content: space-between
}

.flex-space-between {
    flex-direction: space-between
}

.justify-space-around {
    justify-content: space-around
}

.items-space-around {
    align-items: space-around
}

.justify-items-space-around {
    justify-items: space-around
}

.content-space-around {
    align-content: space-around
}

.flex-space-around {
    flex-direction: space-around
}

.justify-space-evenly {
    justify-content: space-evenly
}

.items-space-evenly {
    align-items: space-evenly
}

.justify-items-space-evenly {
    justify-items: space-evenly
}

.content-space-evenly {
    align-content: space-evenly
}

.flex-space-evenly {
    flex-direction: space-evenly
}

.justify-flex-start {
    justify-content: flex-start
}

.items-flex-start {
    align-items: flex-start
}

.justify-items-flex-start {
    justify-items: flex-start
}

.content-flex-start {
    align-content: flex-start
}

.flex-flex-start {
    flex-direction: flex-start
}

.justify-flex-end {
    justify-content: flex-end
}

.items-flex-end {
    align-items: flex-end
}

.justify-items-flex-end {
    justify-items: flex-end
}

.content-flex-end {
    align-content: flex-end
}

.flex-flex-end {
    flex-direction: flex-end
}

.justify-column {
    justify-content: column
}

.items-column {
    align-items: column
}

.justify-items-column {
    justify-items: column
}

.content-column {
    align-content: column
}

.flex-column {
    flex-direction: column
}

.justify-column-reverse {
    justify-content: column-reverse
}

.items-column-reverse {
    align-items: column-reverse
}

.justify-items-column-reverse {
    justify-items: column-reverse
}

.content-column-reverse {
    align-content: column-reverse
}

.flex-column-reverse {
    flex-direction: column-reverse
}

.justify-row {
    justify-content: row
}

.items-row {
    align-items: row
}

.justify-items-row {
    justify-items: row
}

.content-row {
    align-content: row
}

.flex-row {
    flex-direction: row
}

.justify-row-reverse {
    justify-content: row-reverse
}

.items-row-reverse {
    align-items: row-reverse
}

.justify-items-row-reverse {
    justify-items: row-reverse
}

.content-row-reverse {
    align-content: row-reverse
}

.flex-row-reverse {
    flex-direction: row-reverse
}

.position-absolute {
    position: absolute
}

.position-relative {
    position: relative
}

.position-static {
    position: static
}

.position-sticky {
    position: sticky
}

.d-block {
    display: block
}

.d-inline-block {
    display: inline-block
}

.d-inline {
    display: inline
}

.d-flex {
    display: flex
}

.d-grid {
    display: grid
}

.d-inline-flex {
    display: inline-flex
}

.d-inline-grid {
    display: inline-grid
}

.d-table {
    display: table
}

.d-inline-table {
    display: inline-table
}

.d-list-item {
    display: list-item
}

.d-none {
    display: none
}

.d-contents {
    display: contents
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-revert {
    text-align: revert
}

.text-10 {
    font-size: .625rem
}

.text-12 {
    font-size: .75rem
}

.text-14 {
    font-size: .875rem
}

.text-16 {
    font-size: 1rem
}

.text-18 {
    font-size: 1.125rem
}

.text-22 {
    font-size: 1.375rem
}

.text-24 {
    font-size: 1.5rem
}

.weight-100 {
    font-weight: 100 !important
}

.weight-200 {
    font-weight: 200 !important
}

.weight-300 {
    font-weight: 300 !important
}

.weight-400 {
    font-weight: 400 !important
}

.weight-500 {
    font-weight: 500 !important
}

.weight-600 {
    font-weight: 600 !important
}

.weight-700 {
    font-weight: 700 !important
}

.weight-800 {
    font-weight: 800 !important
}

.weight-900 {
    font-weight: 900 !important
}

.weight-bold {
    font-weight: 700 !important
}

.weight-bolder {
    font-weight: bolder !important
}

.bg-marigold {
    background-color: #fdcf39
}

.bg-primary-600 {
    background-color: #ca9b02
}

.bg-primary-500 {
    background-color: #f2ba02
}

.bg-primary-400 {
    background-color: #fdc921
}

.bg-primary-300-main {
    background-color: #fdcf39
}

.bg-primary-200 {
    background-color: #fedd72
}

.bg-primary-100 {
    background-color: #fee79a
}

.bg-primary-50 {
    background-color: #fef0c2
}

.bg-neutral-600 {
    background-color: #131316
}

.bg-neutral-500 {
    background-color: #1c1c21
}

.bg-neutral-400 {
    background-color: #26262c
}

.bg-neutral-300-main {
    background-color: #2f2f37
}

.bg-neutral-200 {
    background-color: #42424d
}

.bg-neutral-100 {
    background-color: #545463
}

.bg-neutral-50 {
    background-color: #717184
}

.bg-neutral-45 {
    background-color: #868698
}

.bg-neutral-40 {
    background-color: #9c9cab
}

.bg-neutral-35 {
    background-color: #b2b2bd
}

.bg-neutral-30 {
    background-color: #bdbdc7
}

.bg-neutral-25 {
    background-color: #c8c8d0
}

.bg-neutral-20 {
    background-color: #d3d3d9
}

.bg-neutral-10 {
    background-color: #dedee3
}

.bg-secondary-600 {
    background-color: #09090b
}

.bg-secondary-500 {
    background-color: #151518
}

.bg-secondary-400-main {
    background-color: #1b1b1f
}

.bg-secondary-300 {
    background-color: #26262c
}

.bg-secondary-200 {
    background-color: #2f2f37
}

.bg-secondary-100 {
    background-color: #393941
}

.bg-secondary-50 {
    background-color: #42424c
}

.bg-success-600 {
    background-color: #1d722b
}

.bg-success-500 {
    background-color: #269238
}

.bg-success-400 {
    background-color: #2eb244
}

.bg-success-300-main {
    background-color: #3ccd53
}

.bg-success-200 {
    background-color: #5dd571
}

.bg-success-100 {
    background-color: #7dde8d
}

.bg-success-50 {
    background-color: #9ee6aa
}

.bg-warning-600 {
    background-color: #724a1d
}

.bg-warning-500 {
    background-color: #925f26
}

.bg-warning-400 {
    background-color: #b2752e
}

.bg-warning-300-main {
    background-color: #cd8a3c
}

.bg-warning-200 {
    background-color: #d59d5d
}

.bg-warning-100 {
    background-color: #deb17d
}

.bg-warning-50 {
    background-color: #e6c49e
}

.bg-error-600 {
    background-color: #72231d
}

.bg-error-500 {
    background-color: #922d26
}

.bg-error-400 {
    background-color: #b2372e
}

.bg-error-300-main {
    background-color: #cd453c
}

.bg-error-200 {
    background-color: #d5655d
}

.bg-error-100 {
    background-color: #de847d
}

.bg-error-50 {
    background-color: #e6a39e
}

.bg-background-600 {
    background-color: #101011
}

.bg-background-500-main {
    background-color: #161617
}

.bg-background-400 {
    background-color: #28282a
}

.bg-text-text-c {
    background-color: #ffffffde
}

.color-marigold {
    color: #fdcf39
}

.color-primary-600 {
    color: #ca9b02
}

.color-primary-500 {
    color: #f2ba02
}

.color-primary-400 {
    color: #fdc921
}

.color-primary-300-main {
    color: #fdcf39
}

.color-primary-200 {
    color: #fedd72
}

.color-primary-100 {
    color: #fee79a
}

.color-primary-50 {
    color: #fef0c2
}

.color-neutral-600 {
    color: #131316
}

.color-neutral-500 {
    color: #1c1c21
}

.color-neutral-400 {
    color: #26262c
}

.color-neutral-300-main {
    color: #2f2f37
}

.color-neutral-200 {
    color: #42424d
}

.color-neutral-100 {
    color: #545463
}

.color-neutral-50 {
    color: #717184
}

.color-neutral-45 {
    color: #868698
}

.color-neutral-40 {
    color: #9c9cab
}

.color-neutral-35 {
    color: #b2b2bd
}

.color-neutral-30 {
    color: #bdbdc7
}

.color-neutral-25 {
    color: #c8c8d0
}

.color-neutral-20 {
    color: #d3d3d9
}

.color-neutral-10 {
    color: #dedee3
}

.color-secondary-600 {
    color: #09090b
}

.color-secondary-500 {
    color: #151518
}

.color-secondary-400-main {
    color: #1b1b1f
}

.color-secondary-300 {
    color: #26262c
}

.color-secondary-200 {
    color: #2f2f37
}

.color-secondary-100 {
    color: #393941
}

.color-secondary-50 {
    color: #42424c
}

.color-success-600 {
    color: #1d722b
}

.color-success-500 {
    color: #269238
}

.color-success-400 {
    color: #2eb244
}

.color-success-300-main {
    color: #3ccd53
}

.color-success-200 {
    color: #5dd571
}

.color-success-100 {
    color: #7dde8d
}

.color-success-50 {
    color: #9ee6aa
}

.color-warning-600 {
    color: #724a1d
}

.color-warning-500 {
    color: #925f26
}

.color-warning-400 {
    color: #b2752e
}

.color-warning-300-main {
    color: #cd8a3c
}

.color-warning-200 {
    color: #d59d5d
}

.color-warning-100 {
    color: #deb17d
}

.color-warning-50 {
    color: #e6c49e
}

.color-error-600 {
    color: #72231d
}

.color-error-500 {
    color: #922d26
}

.color-error-400 {
    color: #b2372e
}

.color-error-300-main {
    color: #cd453c
}

.color-error-200 {
    color: #d5655d
}

.color-error-100 {
    color: #de847d
}

.color-error-50 {
    color: #e6a39e
}

.color-background-600 {
    color: #101011
}

.color-background-500-main {
    color: #161617
}

.color-background-400 {
    color: #28282a
}

.color-text-text-c {
    color: #ffffffde
}

@media only screen and (min-width: 0) {
    .up-xs-block {
        display: block
    }
}

@media only screen and (max-width: 0) {
    .down-xs-block {
        display: block
    }
}

@media only screen and (min-width: 0) {
    .up-xs-flex {
        display: flex
    }
}

@media only screen and (max-width: 0) {
    .down-xs-flex {
        display: flex
    }
}

@media only screen and (min-width: 0) {
    .up-xs-none {
        display: none
    }
}

@media only screen and (max-width: 0) {
    .down-xs-none {
        display: none
    }
}

@media only screen and (min-width: 576px) {
    .up-sm-block {
        display: block
    }
}

@media only screen and (max-width: 576px) {
    .down-sm-block {
        display: block
    }
}

@media only screen and (min-width: 576px) {
    .up-sm-flex {
        display: flex
    }
}

@media only screen and (max-width: 576px) {
    .down-sm-flex {
        display: flex
    }
}

@media only screen and (min-width: 576px) {
    .up-sm-none {
        display: none
    }
}

@media only screen and (max-width: 576px) {
    .down-sm-none {
        display: none
    }
}

@media only screen and (min-width: 768px) {
    .up-md-block {
        display: block
    }
}

@media only screen and (max-width: 768px) {
    .down-md-block {
        display: block
    }
}

@media only screen and (min-width: 768px) {
    .up-md-flex {
        display: flex
    }
}

@media only screen and (max-width: 768px) {
    .down-md-flex {
        display: flex
    }
}

@media only screen and (min-width: 768px) {
    .up-md-none {
        display: none
    }
}

@media only screen and (max-width: 768px) {
    .down-md-none {
        display: none
    }
}

@media only screen and (min-width: 992px) {
    .up-lg-block {
        display: block
    }
}

@media only screen and (max-width: 992px) {
    .down-lg-block {
        display: block
    }
}

@media only screen and (min-width: 992px) {
    .up-lg-flex {
        display: flex
    }
}

@media only screen and (max-width: 992px) {
    .down-lg-flex {
        display: flex
    }
}

@media only screen and (min-width: 992px) {
    .up-lg-none {
        display: none
    }
}

@media only screen and (max-width: 992px) {
    .down-lg-none {
        display: none
    }
}

@media only screen and (min-width: 1200px) {
    .up-xl-block {
        display: block
    }
}

@media only screen and (max-width: 1200px) {
    .down-xl-block {
        display: block
    }
}

@media only screen and (min-width: 1200px) {
    .up-xl-flex {
        display: flex
    }
}

@media only screen and (max-width: 1200px) {
    .down-xl-flex {
        display: flex
    }
}

@media only screen and (min-width: 1200px) {
    .up-xl-none {
        display: none
    }
}

@media only screen and (max-width: 1200px) {
    .down-xl-none {
        display: none
    }
}

@media only screen and (min-width: 1400px) {
    .up-xxl-block {
        display: block
    }
}

@media only screen and (max-width: 1400px) {
    .down-xxl-block {
        display: block
    }
}

@media only screen and (min-width: 1400px) {
    .up-xxl-flex {
        display: flex
    }
}

@media only screen and (max-width: 1400px) {
    .down-xxl-flex {
        display: flex
    }
}

@media only screen and (min-width: 1400px) {
    .up-xxl-none {
        display: none
    }
}

@media only screen and (max-width: 1400px) {
    .down-xxl-none {
        display: none
    }
}

:root {
    --marigold: #FDCF39;
    --primary-600: #ca9b02;
    --primary-500: #f2ba02;
    --primary-400: #fdc921;
    --primary-300-main: #fdcf39;
    --primary-200: #fedd72;
    --primary-100: #fee79a;
    --primary-50: #fef0c2;
    --neutral-600: #131316;
    --neutral-500: #1c1c21;
    --neutral-400: #26262c;
    --neutral-300-main: #2f2f37;
    --neutral-200: #42424d;
    --neutral-100: #545463;
    --neutral-50: #717184;
    --neutral-45: #868698;
    --neutral-40: #9c9cab;
    --neutral-35: #b2b2bd;
    --neutral-30: #bdbdc7;
    --neutral-25: #c8c8d0;
    --neutral-20: #d3d3d9;
    --neutral-10: #dedee3;
    --secondary-600: #09090b;
    --secondary-500: #151518;
    --secondary-400-main: #1b1b1f;
    --secondary-300: #26262c;
    --secondary-200: #2f2f37;
    --secondary-100: #393941;
    --secondary-50: #42424c;
    --success-600: #1d722b;
    --success-500: #269238;
    --success-400: #2eb244;
    --success-300-main: #3ccd53;
    --success-200: #5dd571;
    --success-100: #7dde8d;
    --success-50: #9ee6aa;
    --warning-600: #724a1d;
    --warning-500: #925f26;
    --warning-400: #b2752e;
    --warning-300-main: #cd8a3c;
    --warning-200: #d59d5d;
    --warning-100: #deb17d;
    --warning-50: #e6c49e;
    --error-600: #72231d;
    --error-500: #922d26;
    --error-400: #b2372e;
    --error-300-main: #cd453c;
    --error-200: #d5655d;
    --error-100: #de847d;
    --error-50: #e6a39e;
    --background-600: #101011;
    --background-500-main: #161617;
    --background-400: #28282a;
    --text-text-c: rgba(255, 255, 255, .87)
}

.container {
    width: calc(100% - 32px);
    max-width: 1200px
}

.profile-container {
    width: calc(100% - 32px);
    max-width: 1172px
}

.cursor-pointer {
    cursor: pointer
}

.w-100 {
    width: 100%
}

.hide-scrollbar {
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    scrollbar-color: transparent;
    -ms-overflow-style: none
}

.hide-scrollbar::-webkit-scrollbar {
    display: none
}

html,
body {
    font-family: Inter;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    background: #161617
}

html.unscrollable,
body.unscrollable {
    overflow: hidden
}

a {
    color: #ca993a;
    text-decoration: none;
    transition: filter .2s ease-in-out;
    cursor: pointer
}

a:hover {
    filter: brightness(1.1)
}

input,
button {
    outline: none;
    outline-offset: 0
}

.skeleton {
    background-color: #26262c !important;
    background-image: linear-gradient(90deg, rgba(113, 113, 132, 0), rgba(113, 113, 132, .5), rgba(113, 113, 132, 0)) !important;
    background-size: 40px 100% !important;
    background-repeat: no-repeat !important;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    color: transparent
}

.missions-pag-main .swiper-slide:last-of-type {
    margin-right: 0
}

@keyframes shine {
    to {
        background-position: right -40px top 0
    }
}

.h-100 {
    height: 100%
}

.swiper-wrapper-auto .swiper-slide {
    width: auto;
    margin-right: 12px
}

.swiper-wrapper-auto .swiper-slide:last-of-type {
    margin-right: 0
}

.swiper {
    height: 100%;
    padding: 10px !important
}

.is-banner .swiper {
    padding: 0 !important
}

.swiper-button-lock {
    display: none !important
}

.intercom-lightweight-app-launcher {
    bottom: 78px !important
}

.swiper-slide .game-item-normal,
.swiper-slide .skeleton.game-item.normal {
    width: 132px !important;
    height: 184px !important
}

.swiper-slide .game-item-large,
.swiper-slide .skeleton.game-item.large {
    width: 174px !important;
    height: 232px !important
}

.swiper-pagination {
    display: none !important;
    max-width: max-content;
    right: 12px !important;
    left: initial !important;
    height: fit-content
}

@media (max-width: 768px) {
    .swiper-pagination {
        top: 17px !important;
        left: 50% !important;
        transform: translate(-50%) !important
    }
}

.swiper-pagination-bullet {
    background-color: transparent !important;
    opacity: 1 !important;
    width: 25px !important;
    height: 25px !important;
    margin: 0 !important;
    position: relative
}

.swiper-pagination-bullet:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9px;
    height: 9px;
    background-color: #d9d9d9;
    border-radius: 50%
}

.swiper-pagination-bullet:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9px;
    height: 9px;
    background-color: #fdcf39;
    border-radius: 50%
}

.swiper-pagination-bullet-active {
    background-color: transparent !important;
    opacity: 1 !important;
    width: 25px !important;
    height: 25px !important;
    position: relative
}

.swiper-pagination-bullet-active:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9px;
    height: 9px;
    background-color: #fdcf39;
    border-radius: 50%
}

.swiper-pagination-bullet-active:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 1px solid #fdcf39;
    border-radius: 50%
}

.is-banner .container-start {
    margin-bottom: 0 !important
}

.is-banner .swiper-pagination {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, -50%);
    top: 50%
}

@media (max-width: 768px) {
    .is-banner .swiper-pagination {
        flex-direction: row
    }
}

.expandable-wrapper_content strong {
    pointer-events: none
}

.balance-select--custom {
    display: flex
}

.balance-select--custom .balance-select-handle {
    flex: 1 0 0
}

.balance-select--custom .balance-select-handle .wrap {
    width: 100%;
    position: relative
}

.balance-select--custom .balance-select-handle .wrap .wrap-img img {
    width: 100%;
    height: 100%
}

.balance-select--custom .balance-select-handle .wrap .arrow {
    right: 20px
}

.balance-select--custom .balance-select-container>.balance-select-container {
    left: 0;
    transform: none
}

.balance-select-handle .wrap-img img {
    width: 100%;
    height: 100%
}

.header-link:hover .hoverable {
    fill: #fdcf39
}

.bearish:hover .hoverable {
    fill: #000
}

.active .hoverable {
    fill: #fdcf39
}

.sm-down-show-image {
    height: 100%;
    display: none
}

@media (max-width: 575px) {
    .sm-down-show-image {
        display: block
    }
}

.sm-up-show-image {
    height: 100%;
    display: none
}

@media (min-width: 576px) {
    .sm-up-show-image {
        display: block
    }
}

.md-down-show-image {
    height: 100%;
    display: none
}

@media (max-width: 767px) {
    .md-down-show-image {
        display: block
    }
}

.md-up-show-image {
    height: 100%;
    display: none
}

@media (min-width: 768px) {
    .md-up-show-image {
        display: block
    }
}

.samurai-swiper .swiper-pagination {
    display: none !important
}

.lobby .s-wrapper .container-start {
    margin-bottom: 0 !important
}

.lobby .s-wrapper .action-buttons .btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2
}

.lobby .s-wrapper .action-buttons .btn:first-of-type {
    right: inherit;
    left: 0
}

.lobby .s-wrapper .action-buttons .btn:hover {
    transform: translateY(-50%) scale(1.07)
}

.slider-message .swiper {
    padding: 0 0 38px !important
}

.slider-message .swiper-slide {
    width: auto;
    height: 100%;
    transition: transform .3s ease;
    transform: scale(.85);
    display: flex;
    align-items: center
}

.slider-message .swiper-slide.swiper-slide-active {
    transform: scale(1);
    opacity: 1
}

.slider-message .swiper-slide.swiper-slide-next,
.slider-message .swiper-slide.swiper-slide-prev {
    transform: scale(.9);
    opacity: .5
}

.slider-message .swiper-slide .slide-content {
    height: 100%
}

@media (max-width: 576px) {
    .betby-sportsbook .balance-select-container--betby {
        position: fixed !important
    }
}

.alert.svelte-zm5m6l.svelte-zm5m6l {
    position: relative;
    color: #fff;
    padding: 19px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    flex-direction: column;
    margin: 0 auto .5rem;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0;
    box-shadow: -10px 10px 38px #00000073;
    border: 1px solid #2f2f37;
    background: #1b1b1f
}

.alert.svelte-zm5m6l .learn-more.svelte-zm5m6l {
    display: flex;
    margin-left: 50px;
    width: 331px;
    height: 40px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #26262c;
    color: #dedee3;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer
}

.alert.svelte-zm5m6l .top-border.svelte-zm5m6l {
    border-radius: 0 0 4px 4px;
    width: 42px;
    height: 4px;
    position: absolute;
    top: 0
}

.alert.svelte-zm5m6l .type.svelte-zm5m6l {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-shrink: 0
}

.alert.svelte-zm5m6l .type .border.svelte-zm5m6l {
    position: absolute;
    width: 35px;
    height: 1px;
    top: 42px
}

.alert.svelte-zm5m6l>.close.svelte-zm5m6l {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px
}

.alert.svelte-zm5m6l .text.svelte-zm5m6l {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 5px
}

.alert.svelte-zm5m6l .text .heading.svelte-zm5m6l {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%
}

.success.svelte-zm5m6l .type.svelte-zm5m6l {
    background: rgba(60, 205, 83, .2196078431);
    border-bottom: 1px solid #3ccd53
}

.success.svelte-zm5m6l .top-border.svelte-zm5m6l {
    background: #3ccd53
}

.error.svelte-zm5m6l .type.svelte-zm5m6l {
    background: rgba(205, 69, 60, .2196078431);
    border-bottom: 1px solid #cd453c
}

.error.svelte-zm5m6l .top-border.svelte-zm5m6l {
    background: #cd453c
}

.warning.svelte-zm5m6l .type.svelte-zm5m6l {
    background: rgba(205, 138, 60, .2196078431);
    border-bottom: 1px solid #cd8a3c
}

.warning.svelte-zm5m6l .top-border.svelte-zm5m6l {
    background: #cd8a3c
}

.description.svelte-bt3k3r {
    font-size: 14px;
    font-family: Inter;
    color: #9c9cab;
    font-weight: 300;
    line-height: 130%
}

section.svelte-bt3k3r {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9999999;
    width: 430px;
    position: fixed;
    bottom: 0;
    right: 0
}

@media (max-width: 992px) {
    section.svelte-bt3k3r {
        transform: translate(3%);
        bottom: 75px;
        width: 93%;
        left: 0
    }
}